import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ApplePrice } from "./components/ApplePrice"
import { AppleProductCard } from "./components/AppleProductCard"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  margin: 0px;
  padding: 0.5rem;
  flex-wrap: wrap;
  grid-gap: 2rem;
`

const Title = styled.h5`
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
`

const ShopText = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-weight: 600;
`

const ShowAllButton = styled.div`
  border: 1px solid #a7a7a7;
  padding: 1rem 1.5rem;
  color: #f0eaea;
  font-weight: 600;
  font-size: 1rem;
  background-color: #151414;
  border: none;
  border-radius: 3px;
  margin: 1rem auto;
  text-align: center;
  max-width: 180px;
`

const GetAppleProducts = ({ product, title }) => {
  const [productsData, setProductsData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const delimiter =
    product.includes("iphone") || product.includes("ipad") ? "-" : ""
  const productSlug = product
    .split(" ")
    .join(delimiter)
    .toLowerCase()
  const body = { product }

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch("/.netlify/functions/apple-products", {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      })
      const result = await data.json()
      setIsLoading(true)
      setProductsData(result)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const trackClick = () => {
    typeof window !== "undefined" &&
      window.gtag("event", `MresellClick`, {
        event_label: product,
        event_category: "ShowAllApple",
      })
  }

  const productCards =
    productsData && productsData.products
      ? productsData.products.map((product, i) => {
          return <AppleProductCard key={i} product={product} />
        })
      : null

  if (isLoading) return <Title>Ladataan tuotteita, pieni hetki...</Title>

  if (!productCards || productCards.length === 0)
    return <Title>Käytettyjä ei ole tällä hetkellä myynnissä</Title>

  return (
    <Container>
      <Title>{title}</Title>
      <ShopText>Nyt myynnissä mresell.fi</ShopText>
      <ApplePrice
        price={
          productsData &&
          productsData.cheapest &&
          productsData.cheapest.productPrice
        }
        product={
          productsData &&
          productsData.cheapest &&
          productsData.cheapest.productName
        }
        title="Halvin"
      />
      <ApplePrice
        price={
          productsData &&
          productsData.mostExpensive &&
          productsData.mostExpensive.productPrice
        }
        product={
          productsData &&
          productsData.mostExpensive &&
          productsData.mostExpensive.productName
        }
        title="Kallein"
      />
      <ProductContainer>
        {isLoading || !productsData ? <p>Ladataan...</p> : productCards}
      </ProductContainer>
      {productsData && productsData.restCount > 0 && (
        <a
          href={`https://dot.mresell.fi/t/t?a=1469733318&as=1423533026&t=2&tk=1&epi=${productSlug}&url=https://mresell.fi/webshop/${productSlug}/`}
          rel="nofollow"
          target="_blank"
        >
          <ShowAllButton onClick={() => trackClick()}>
            Näytä kaikki ({productsData.restCount}kpl)
          </ShowAllButton>
        </a>
      )}
    </Container>
  )
}

export default GetAppleProducts
