import React from "react"
import styled from "styled-components"
import ProductImage from "../../../../images/OperatorImage"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0.5rem;
  justify-content: center;
  text-align: center;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
`

const Name = styled.p`
  font-size: 0.8rem;
  color: #3c3c3c;
  font-weight: 300;
`

const ImgContainer = styled.div`
  margin: 0rem auto;
`

const Price = styled.p`
  color: #3c3c3c;
  font-size: 1.4rem;
`

const Guarantee = styled.p`
  background-color: #a35dbc;
  margin: 0rem auto;
  color: white;
  font-size: 0.7rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
`

const CardContainer = styled.div`
  margin: 0rem;
  padding: 0rem;
`

const Button = styled.div`
  border: 1px solid #a7a7a7;
  padding: 1rem 1.5rem;
  color: #272727;
  font-weight: 600;
  font-size: 1rem;
  background-color: white;
  border-radius: 3px;
  margin: 0.2rem auto;
  ${Container}:hover & {
    transition: background-color 1s ease-out;
    background-color: black;
    color: white;
  }
`

const Shipping = styled.p`
  font-size: 0.7rem;
  font-weight: 300;
  color: #a35dbc;
`

const Link = styled.a``

export const AppleProductCard = product => {
  console.log("PRoduct", product)
  const {
    productName,
    productPrice,
    imageUrl,
    trackingUrl,
    shipping,
  } = product.product

  const trackClick = () => {
    typeof window !== "undefined" &&
      window.gtag("event", `MresellClick`, {
        event_label: productName,
        event_category: "AppleCard",
      })
  }

  return (
    <CardContainer onClick={() => trackClick()}>
      <Link href={trackingUrl} rel="nofollow" target="_blank">
        <Container>
          <Name>{productName}</Name>
          <Guarantee>Takuu 12kk</Guarantee>
          <ImgContainer>
            <img width="170px" src={imageUrl} alt={productName} />
          </ImgContainer>
          <Price>{productPrice.toFixed(0)}€</Price>
          <Button>Näytä tuote</Button>
          <Shipping>Toimitus {shipping}€</Shipping>
          <ProductImage imgName="mresell.png" />
        </Container>
      </Link>
    </CardContainer>
  )
}
