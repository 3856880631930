import React from "react"
import styled from "styled-components"
import { device } from "../../../../utils/mediaQueries"

const Link = styled.a`
  text-decoration: none;
  color: blue;
  font-size: 1.3rem;
  padding: 0.7rem;
  width: 100%;
  @media ${device.tablet} {
    font-size: 1.2rem;
    padding: 0.2rem;
    font-weight: 800;
  }
`
const SmallText = styled.p`
  font-size: 0.9rem;
  font-weight: 200;
`

const Container = styled.div`
  background: #edeeff;
  padding: 1.3rem;
`

const Title = styled.h5`
  font-size: 0.8rem;
  font-weight: 500;
`

const trackClick = () => {
  typeof window !== "undefined" &&
    window.gtag("event", `AdClick`, {
      event_label: "iphoneAd",
      event_category: "Apple",
    })
}

const IphoneAd = () => (
  <Container>
    <Title>Iphone jopa -50% alkuperäisestä hinnasta</Title>
    <SmallText>
      Käytetty iPhone on vastuullinen tapa ostaa uusi puhelin. Säästät jopa
      satoja euroja rahaa sekä luontoa, kun ostat seuraavan puhelimesi
      käytettynä. Tutustu tarjontaan ja valitse sopivin. Hinnat alkaen jopa alle
      200€.
    </SmallText>
    <Link
      onClick={() => trackClick()}
      href="/blogi/mista-ostaa-kaytetty-iphone/"
      target="_blank"
    >
      Käytetty iPhone 12kk takuulla {">>"}
    </Link>
  </Container>
)

export default IphoneAd
