import React from "react"
import styled from "styled-components"
import { trackClick } from "../../utils/trackingHelp"
import CvAdImage from "../images/CvAdImage"

const Container = styled.div`
  padding-left: 0.3rem;
  margin-top: 0.3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
`

const Ad = styled.div`
  padding: 0.3rem 0.4rem;
  background-color: blue;
  color: white;
  font-size: 0.7rem;
  border-radius: 3px;
  width: 70px;
  text-align: center;
`

const Text = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
`
const TextContainer = styled.div`
  padding: 0.3rem 2rem;
`

export const CvAd = ({ data }) => {
  return (
    <Container onClick={() => trackClick("adCV", "adClick", "blogPage")}>
      {/* <Link href="https://www.cvpohjat.fi/" target="_blank">
        <Image
          width="400px"
          height="192px"
          layout="responsive"
          src="/cv-pohjat.png"
        />
        <Box>
          <Tag colorScheme="green" marginRight="1rem" marginTop="0.3rem">
            Mainos
          </Tag> */}
      <a href="https://www.cvpohjat.fi/" target="_blank">
        <CvAdImage />
        <TextContainer>
          <Ad>mainos</Ad>
          <Text>
            Luo vain minuuteissa upea CV pohja ja maksimoi työnantajien huomio.
            Kokeile ilmaiseksi osoitteessa cvpohjat.fi {">>>"}
          </Text>
        </TextContainer>
      </a>
    </Container>
  )
}
