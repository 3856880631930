import React from "react"
import { device } from "../../../utils/mediaQueries"
import styled from "styled-components"

const DesktopCard = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`

const MobileCard = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`

const DnaAd = () => (
  <>
    <DesktopCard>
      <a
        href="https://online.adservicemedia.dk/cgi-bin/click.pl?bid=1660438&media_id=61404"
        target="_blank"
      >
        <img src="https://impr.adservicemedia.dk/cgi-bin/Services/ImpressionService/Image.pl?bid=1660438&media_id=61404" />
      </a>
    </DesktopCard>
    <MobileCard>
      <a
        href="https://online.adservicemedia.dk/cgi-bin/click.pl?bid=1660429&media_id=61404"
        target="_blank"
      >
        <img src="https://impr.adservicemedia.dk/cgi-bin/Services/ImpressionService/Image.pl?bid=1660429&media_id=61404" />
      </a>
    </MobileCard>
  </>
)

export default DnaAd
