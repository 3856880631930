import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Link = styled.a`
  text-decoration: none;
  color: blue;
  font-size: 1.3rem;
  padding: 0.7rem;
  width: 100%;
  @media ${device.tablet} {
    font-size: 1.2rem;
    padding: 0.2rem;
    font-weight: 800;
  }
`
const SmallText = styled.p`
  font-size: 0.9rem;
  font-weight: 200;
`

const Container = styled.div`
  background: #edeeff;
  padding: 1.3rem;
`

const Title = styled.h5`
  font-size: 0.8rem;
  font-weight: 500;
`

const trackClick = () => {
  typeof window !== "undefined" &&
    window.gtag("event", `BlogiKlikki`, {
      event_label: "click",
      event_category: "Elisatietoturva",
    })
}

const AntiVirusLink = () => (
  <Container>
    <Title>Elisa tietoturvapaketti</Title>
    <Link
      onClick={() => trackClick()}
      href="/tilaa/elisatietoturva/"
      target="_blank"
      rel="nofollow"
    >
      Kokeile ilmaiseksi 1kk {">>"}
    </Link>
    <SmallText>
      Elisa tietoturva paketin voi irtisanoa missä vaiheessa tahansa, jos et
      koekaan sitä tarpeelliseksi.
    </SmallText>
  </Container>
)

export default AntiVirusLink
