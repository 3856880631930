import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const Image = styled(Img)`
  margin: 1.3rem;

  z-index: 1000;
`

const CvAdImage = () => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === "cv-pohjat.png"
      )
      if (!image) {
        return null
      }
      return <Image fluid={image.node.fluid} alt={"cv pohjat"} />
    }}
  />
)
export default CvAdImage
