import React from "react"
import styled from "styled-components"

const Title = styled.p`
  padding: 0.1rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  margin-right: 1rem;
  font-weight: 800;
  background-color: black;
  color: white;
  padding: 0.1rem 1rem;
  border-radius: 20px;
`

const Product = styled.p`
  padding: 0.1rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  margin-right: 1rem;
  font-weight: 300;
`

const Price = styled.p`
  padding: 0.1rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-weight: 800;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
`

export const ApplePrice = ({ title, product, price }) => {
  return (
    <Container>
      <Title>{title} </Title>
      <Product>{product} </Product>
      <Price>{price && price.toFixed(0)}€</Price>
    </Container>
  )
}
